import "./App.css";
import React, { useLayoutEffect } from "react";

import { Route, Routes, useLocation } from "react-router-dom";
import TechStack from "./tech_stack";
import Projects from "./projects";
import Home from "./home";
import Contact from "./contact";

function App() {
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tech" element={<TechStack />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
