import React from "react";
import { FaJava } from "react-icons/fa";
import {
  SiC,
  SiCsharp,
  SiCss3,
  SiDart,
  SiGnubash,
  SiHtml5,
  SiJavascript,
  SiKotlin,
  SiPython,
  SiSwift,
  SiTypescript,
} from "react-icons/si";

function Languages() {
  return (
    <div className="pt-20">
      <div className="flex flex-wrap items-center justify-center">
        <div className="card">
          <SiSwift className="icon" />
          <p className="text">Swift</p>
        </div>
        <div className="card">
          <SiTypescript className="icon" />
          <p className="text ">TypeScript</p>
        </div>
        <div className="card">
          <FaJava className="icon" />
          <p className="text ">Java</p>
        </div>
        <div className="card">
          <SiPython className="icon" />
          <p className="text ">Python</p>
        </div>
        <div className="card">
          <SiJavascript className="icon" />
          <p className="text ">JavaScript</p>
        </div>
        <div className="card">
          <SiKotlin className="icon" />
          <p className="text ">Kotlin</p>
        </div>
        <div className="card">
          <SiDart className="icon" />
          <p className="text ">Dart</p>
        </div>
        <div className="card">
          <SiC className="icon" />
          <p className="text ">C</p>
        </div>
        <div className="card">
          <SiCsharp className="icon" />
          <p className="text ">C#</p>
        </div>

        <div className="card">
          <SiGnubash className="icon" />
          <p className="text ">Bash</p>
        </div>
        <div className="card">
          <SiHtml5 className="icon" />
          <p className="text ">HTML</p>
        </div>
        <div className="card">
          <SiCss3 className="icon" />
          <p className="text ">CSS</p>
        </div>
      </div>
    </div>
  );
}

export default Languages;
