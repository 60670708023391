import React from "react";
import NavBar from "./components/NavBar/navbar";
import Footer from "./components/footer/footer";
import ProjectsComp from "./components/Projects/projects";

function Projects() {
  return (
    <>
      <div className="bg-[#23385f]">
        <NavBar />
        <ProjectsComp />
        <Footer />
      </div>
    </>
  );
}

export default Projects;
