import "./App.css";
import React from "react";
import NavBar from "./components/NavBar/navbar";
import Stack from "./components/stack.tsx/stack";
import Footer from "./components/footer/footer";

function TechStack() {
  return (
    <div>
      <NavBar />
      <Stack />
      <Footer />
    </div>
  );
}

export default TechStack;
