import React from "react";
import NavBar from "./components/NavBar/navbar";

import ContactComp from "./components/contact/contactcomp";
import Footer from "./components/footer/footer";

function Contact() {
  return (
    <div className="bg-[#23385f] h-screen">
      <NavBar />
      <ContactComp />
      <Footer />
    </div>
  );
}

export default Contact;
