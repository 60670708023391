import React from "react";
import ProjectCard from "./project_card";

function ProjectsComp() {
  return (
    <div className="flex flex-col w-full items-center justify-center  bg-gradient-to-t from-[#23385f] to-[#101827] pt-20">
      <p className="pt-5 text-3xl text-center text-white sm:text-4xl lg:text-5xl font-bold trispace">
        Projects
      </p>
      <div className="flex flex-wrap items-center justify-center">
        <ProjectCard
          name="Halcyon"
          desc="Halcyon is app which is as a powerful solution to address problems of mental health."
          image="/projects/halcyon.png"
          link="https://github.com/HacktivatorsMJ/Halcyon"
        />
        <ProjectCard
          name="Know-Money"
          desc="An UPI-powered app excels in documenting
          each payment and offers features designed to
          help you establish and control your budget
          seamlessly."
          image="/projects/know-money.png"
          link="https://github.com/HacktivatorsMJ/Know-Money-Android"
        />
        <ProjectCard
          name="Memorium"
          desc="It is an application designed to enable people living with dementia to create a digital memory book to store their personal information, medication information, journal entries and live a facial recognition feature."
          image="/projects/memorium.png"
          link="https://github.com/ACMDreamTeam/Memorium-android"
        />
        <ProjectCard
          name="Peek-A-Byte"
          desc="A secure messaging application with military grade security using Steganography Technology."
          image="/projects/peek.png"
          link="https://github.com/MohammedAbidNafi/Peek-a-byte"
        />
        <ProjectCard
          name="Messagium"
          desc="An open sourced simple android messaging app built using Java and Firebase."
          image="/projects/messagium.png"
          link="https://github.com/MohammedAbidNafi/Messagium"
        />
        <ProjectCard
          name="Catapolis"
          desc="An open sourced website and public API dedicated to CATS! Built using Bun, Redis and hosted in AWS."
          image="/projects/catapolis.png"
          link="https://github.com/MohammedAbidNafi/Catapolis"
        />
      </div>
    </div>
  );
}

export default ProjectsComp;
