import React from "react";

function TestimonialCard({
  testimony,
  name,
  position,
}: {
  testimony: String;
  name: String;
  position: String;
}) {
  return (
    <div className="p-8 border bg-gray-800 bg-opacity-30 rounded-lg dark:border-gray-600 dark:md:border-gray-600 backdrop-blur-3xl transform duration-300  hover:dark:border-gray-500 mx-3">
      <p className="leading-loose text-gray-500 dark:text-gray-300">
        “{testimony}”
      </p>

      <div className="flex items-center mt-6">
        <div className="mx-4">
          <h1 className="font-semibold text-blue-500">{name}</h1>
          <span className="text-sm text-gray-500 dark:text-gray-300">
            {position}
          </span>
        </div>
      </div>
    </div>
  );
}

export default TestimonialCard;
