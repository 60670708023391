import React from "react";

function About() {
  return (
    <div className="py-20 mx-auto px-4 w-full md:px-8 bg-gradient-to-t from-[#101827] to-[#23385f]">
      <div>
        <p className="text-3xl text-center text-white sm:text-4xl lg:text-5xl font-bold leading-tight mt-5 sm:mt-0 trispace">
          About me
        </p>
      </div>
      <div>
        <p className="text-xl mx-5 text-white trispace mt-10">
          Initiating my coding journey at the age of 14, I ventured into game
          development, laying the groundwork for a diverse skill set. Now, as a
          19-year-old mobile app and web developer based in India, I boast over
          five years of coding experience.
          <br></br>
          <br></br>Transitioning from game development, I've accrued more than
          three years of expertise in crafting innovative mobile applications
          and over two years in building the frontend of websites.<br></br>
          <br></br>
          My journey includes successful contributions to numerous projects,
          showcasing a commitment to excellence. A staunch believer in the power
          of open source, I actively engage in collaborative endeavors,
          recognizing their transformative impact on the tech landscape.{" "}
          <br></br>
          <br></br> At this juncture, I am poised for new challenges, exploring
          emerging technologies to contribute meaningfully to the ever-evolving
          field of software development. The future holds not just a
          continuation of my coding journey but a quest for continual growth and
          innovation.
        </p>
      </div>
    </div>
  );
}

export default About;
