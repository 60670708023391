import React from "react";
import TestimonialCard from "./card";

function Testimonials() {
  const testimonies = [
    {
      testimony:
        "Abid's exceptional achievements in software development at such a young age are truly inspiring. His passion, expertise, and collaborative spirit make him a standout professional. Abid's future is boundless, and his contributions to the field are invaluable.",
      name: "Abdul Basith",
      position: "Founder of AcubeCreative",
    },
    {
      testimony:
        "It's a delight to have Abid leading the Tech Team of ACM MJCET. A truly sincere and dedicated individual, he consistently showcases unwavering commitment and reliability. Abid is a true asset, embodying professionalism and a very strong work ethics.",
      name: "Maheen",
      position: "Chair of ACM MJCET",
    },
  ];

  return (
    <div className="bg-gradient-to-t from-[#23385f] to-[#101827] py-20 ">
      <div className="relative w-full  pt-10 sm:pt-0 mb-10">
        <p className="text-3xl text-center text-white md:text-3xl lg:text-5xl font-bold leading-tight mt-5 sm:mt-0 trispace">
          Testimonials
        </p>
        <div
          className="absolute inset-0 max-w-md mx-auto h-80 blur-[118px] sm:h-72 "
          style={{
            background:
              "linear-gradient(152.92deg, rgba(255, 0, 0, 0.2) 4.54%, rgba(255, 100, 100, 0.26) 34.2%, rgba(255, 0, 0, 0.1) 77.55%)",
          }}
        ></div>
        <div className="grid grid-cols-1 gap-8 mx-auto mt-8 lg:grid-cols-2 xl:mt-10 max-w-7xl">
          {testimonies.map((items, keys) => (
            <TestimonialCard
              testimony={items.testimony}
              name={items.name}
              position={items.position}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
