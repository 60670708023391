import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

function Intro() {
  const dynamicTextRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    const dynamicText = dynamicTextRef.current;
    const words = [
      "Swift Developer",
      "Frontend Engineer",
      "Android Developer",
      "Student",
      "CEO",
    ];
    // Variables to track the position and deletion status of the word
    let wordIndex = 0;
    let charIndex = 0;
    let isDeleting = false;
    const typeEffect = () => {
      if (dynamicText) {
        const currentWord = words[wordIndex];
        const currentChar = currentWord.substring(0, charIndex);
        dynamicText.textContent = currentChar;
        dynamicText.classList.add("stop-blinking");
        if (!isDeleting && charIndex < currentWord.length) {
          // If condition is true, type the next character
          charIndex++;
          setTimeout(typeEffect, 100);
        } else if (isDeleting && charIndex > 0) {
          // If condition is true, remove the previous character
          charIndex--;
          setTimeout(typeEffect, 100);
        } else {
          // If word is deleted then switch to the next word
          isDeleting = !isDeleting;
          dynamicText!.classList.remove("stop-blinking");
          wordIndex = !isDeleting ? (wordIndex + 1) % words.length : wordIndex;
          setTimeout(typeEffect, 1000);
        }
      }
    };

    typeEffect();
  }, []);

  return (
    <div className="flex items-center justify-center w-full md:pt-20 z-10">
      <div className="relative w-full bg-gradient-to-t from-[#23385f] to-[#101827] pt-20 sm:pt-12 pb-10">
        <div className="container mt-5 mx-auto grid grid-cols-1 md:grid-cols-2 items-center md:gap-20 px-5">
          <div className="relative sm:mt-0 mt-10 mb-10 px-6 sm:px-0">
            <img
              className="w-[250px] h-[250px] rounded-xl md:w-[500px] md:h-[500px] object-cover animate__animated animate__fadeInRight animate__delay-.5s"
              alt=""
              src="/dp.webp"
            />
          </div>
          <div className="content">
            <div className="flex items-center gap-3">
              <hr className="w-10" />
              <span className="md:text-[18px] font-medium text-white trispace">
                Frontend Developer
              </span>
            </div>
            <p className="text-xl text-white md:text-3xl lg:text-5xl font-bold leading-tight mt-5 sm:mt-0 trispace">
              Hola! Abid Nafi here
              <br />
            </p>
            <p className="typing mb-4 text-lg font-extrabold tracking-tight leading-none text-white md:text-xl lg:text-3xl mt-5 trispace">
              I'm a <span className="trispace" ref={dynamicTextRef}></span>
            </p>
            <p className="mt-5 md:text-md text-white trispace">
              Dedicated to refining my skills and staying at the forefront of
              the ever-evolving development and startup landscape.
            </p>
            <div className="flex gap-4 mt-10">
              <Link to="/contact">
                <button className="font-medium text-[16px] flex items-center px-5 py-3 md:py-4 md:px-8 rounded-xl capitalize bg-blue-700 hover:bg-blue-900  relative gap-2 transition duration-300 hover:scale-105 text-white shadow-glass trispace">
                  Get In Touch
                  <span className="animate-ping absolute right-0 top-0 w-3 h-3  rounded-full bg-blue-200 "></span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
