import React from "react";
import {
  SiReact,
  SiTailwindcss,
  SiBootstrap,
  SiSvelte,
  SiNextdotjs,
  SiVite,
  SiYarn,
  SiNpm,
  SiAmazonaws,
  SiAndroidstudio,
  SiBun,
  SiFirebase,
  SiFlask,
  SiFlutter,
  SiGit,
  SiGithub,
  SiGooglecloud,
  SiLinux,
  SiNodedotjs,
  SiNumpy,
  SiPandas,
  SiPycharm,
  SiPytorch,
  SiTensorflow,
  SiXcode,
  SiGradle,
  SiExpo,
  SiElectron,
  SiUnity,
  SiMysql,
  SiSupabase,
  SiPostgresql,
} from "react-icons/si";

function Technologies() {
  return (
    <div className="flex items-center justify-center pt-10">
      <div className="flex flex-wrap items-center justify-center">
        <div className="card">
          <SiReact className="icon" />
          <p className="text ">ReactJS</p>
        </div>
        <div className="card">
          <SiNextdotjs className="icon" />
          <p className="text ">NextJS</p>
        </div>
        <div className="card">
          <SiVite className="icon" />
          <p className="text ">Vite</p>
        </div>

        <div className="card">
          <SiTailwindcss className="icon" />
          <p className="text ">Tailwind CSS</p>
        </div>
        <div className="card">
          <SiBootstrap className="icon" />
          <p className="text ">BootStrap</p>
        </div>
        <div className="card">
          <SiSvelte className="icon" />
          <p className="text ">Svelte</p>
        </div>
        <div className="card">
          <SiAndroidstudio className="icon" />
          <p className="text ">Android Studio</p>
        </div>
        <div className="card">
          <SiGradle className="icon" />
          <p className="text ">Gradle</p>
        </div>
        <div className="card">
          <SiXcode className="icon" />
          <p className="text ">xCode</p>
        </div>
        <div className="card">
          <SiReact className="icon" />
          <p className="text ">React Native</p>
        </div>
        <div className="card">
          <SiExpo className="icon" />
          <p className="text ">Expo</p>
        </div>
        <div className="card">
          <SiFlutter className="icon" />
          <p className="text ">Flutter</p>
        </div>
        <div className="card">
          <SiNodedotjs className="icon" />
          <p className="text ">Node JS</p>
        </div>
        <div className="card">
          <SiFlask className="icon" />
          <p className="text ">Flask</p>
        </div>

        <div className="card">
          <SiElectron className="icon" />
          <p className="text ">Electron JS</p>
        </div>

        <div className="card">
          <SiNpm className="icon" />
          <p className="text ">NPM</p>
        </div>
        <div className="card">
          <SiYarn className="icon" />
          <p className="text ">Yarn</p>
        </div>
        <div className="card">
          <SiBun className="icon" />
          <p className="text ">Bun</p>
        </div>
        <div className="card">
          <SiFirebase className="icon" />
          <p className="text ">Firebase</p>
        </div>
        <div className="card">
          <SiSupabase className="icon" />
          <p className="text ">Supabase</p>
        </div>

        <div className="card">
          <SiGooglecloud className="icon" />
          <p className="text ">Google Cloud</p>
        </div>
        <div className="card">
          <SiAmazonaws className="icon" />
          <p className="text ">AWS</p>
        </div>

        <div className="card">
          <SiTensorflow className="icon" />
          <p className="text">Tensorflow</p>
        </div>
        <div className="card">
          <SiPytorch className="icon" />
          <p className="text">PyTorch</p>
        </div>

        <div className="card">
          <SiGit className="icon" />
          <p className="text ">Git VCS</p>
        </div>
        <div className="card">
          <SiGithub className="icon" />
          <p className="text ">Github</p>
        </div>
        <div className="card">
          <SiLinux className="icon" />
          <p className="text ">GNU/Linux</p>
        </div>
        <div className="card">
          <SiUnity className="icon" />
          <p className="text ">Unity</p>
        </div>
        <div className="card">
          <SiMysql className="icon" />
          <p className="text ">MySQL</p>
        </div>
        <div className="card">
          <SiPostgresql className="icon" />
          <p className="text ">Postgresql</p>
        </div>
      </div>
    </div>
  );
}

export default Technologies;
