import React from "react";

function Progress({
  label,
  percentage,
}: {
  label: String;
  percentage: String;
}) {
  return (
    <div className="w-[300px] md:w-[30%] bg-gray-800 bg-opacity-30  border-gray-700 border-[1px] backdrop-blur-lg p-5 m-3 rounded-xl transform hover:scale-105 duration-300 cursor-pointer">
      <div className="mb-1 flex justify-between">
        <span className="text-base font-medium text-blue-700 dark:text-white trispace">
          {label}
        </span>
        <span className="text-sm font-medium text-blue-700 dark:text-white">
          {percentage}%
        </span>
      </div>
      <div className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <div
          className="bg-blue-600 h-2.5 rounded-full"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    </div>
  );
}

export default Progress;
