import React from "react";

function Achievements() {
  const stats = [
    {
      data: "3+",
      desc: "Have started/worked on 3+ startups And more coming soon...",
    },
    {
      data: "30+",
      desc: "Have worked on 30+ projects both personal and open-source projects.",
    },
    {
      data: "5+",
      desc: "Participated in 5+ Hackathons and won 2 hackathons. And will participate in more",
    },
  ];

  return (
    <section className="py-20 bg-gradient-to-t from-[#101827] to-[#23385f]">
      <div className="flex flex-col items-center justify-center relative z-10 max-w-screen-xl mx-auto px-4 md:px-8">
        <div className="max-w-2xl xl:mx-auto xl:text-center">
          <h3 className="text-white text-center text-3xl lg:text-5xl font-semibold sm:text-4xl trispace">
            Achievements
          </h3>
        </div>
        <div className="mt-12">
          <ul className="flex-wrap gap-x-12 gap-y-10 items-center space-y-8 sm:space-y-0 sm:flex xl:justify-center">
            {stats.map((item, idx) => (
              <li key={idx} className="sm:max-w-[15rem]">
                <h4 className="text-4xl text-white text-center font-semibold">
                  {item.data}
                </h4>
                <p className="mt-3 text-center text-gray-400 font-medium">
                  {item.desc}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className="absolute inset-0 max-w-md mx-auto h-80 blur-[118px] sm:h-72 "
        style={{
          background:
            "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)",
        }}
      ></div>
    </section>
  );
}

export default Achievements;
