import React from "react";
import Progress from "./progress";

function Profile() {
  return (
    <div className="flex items-center justify-center w-full md:pt-20 bg-gradient-to-t from-[#23385f] to-[#101827]">
      <div className="relative w-full  pt-10 sm:pt-0 mb-10">
        <p className="text-3xl text-center text-white sm:text-4xl lg:text-5xl font-bold leading-tight mt-5 sm:mt-0 trispace">
          My Skills
        </p>
        <div
          className="absolute inset-0 max-w-md mx-auto h-80 blur-[118px] sm:h-72 "
          style={{
            background:
              "linear-gradient(152.92deg,rgba(0, 255, 255, 0.2) 4.54%, rgba(0, 200, 255, 0.26) 34.2%,rgba(0, 255, 255, 0.1) 77.55%)",
          }}
        ></div>
        <div className="flex flex-wrap p-5 items-center justify-center z-10">
          <Progress label="Android App Development" percentage="90" />
          <Progress label="Frontend Development" percentage="75" />
          <Progress label="React Native Development" percentage="75" />
          <Progress label="iOS App Development" percentage="50" />
          <Progress label="Game Development" percentage="50" />
          <Progress label="Leadership Skills" percentage="10" />
        </div>
      </div>
    </div>
  );
}

export default Profile;
