import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { FiCheckCircle } from "react-icons/fi";

function ContactComp() {
  const [submit, setSubmit] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [modal, setModal] = useState(false);

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent the default form submission

    // Your existing logic for handling form submission goes here
    setSubmit(!submit);
    setName("");
    setEmail("");
    setMessage("");

    // Display a success notification
    setModal(true);

    try {
      const formData = new FormData(event.currentTarget);
      await axios.post(
        "https://docs.google.com/forms/d/e/1FAIpQLScrj1aCe0gyu4rK3Jf7DgUMxfxvrh2r4ZhXG1abP3bJPmEhgQ/formResponse",
        formData
      );

      // Handle successful response if needed
    } catch (error: any) {
      console.error("Error:", error.message);
    }
  };

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setMessage(event.target.value);
  };
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <div className="pt-20 bg-gradient-to-t from-[#23385f] to-[#101827]">
      <div className="pt-20">
        <p className="text-white text-center text-3xl font-semibold sm:text-4xl trispace ">
          Get in Touch
        </p>
      </div>
      <section className="text-gray-600 body-font relative">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-1/2 md:w-2/3 mx-auto">
            <form
              className="flex flex-wrap -m-2"
              action="https://docs.google.com/forms/d/e/1FAIpQLScrj1aCe0gyu4rK3Jf7DgUMxfxvrh2r4ZhXG1abP3bJPmEhgQ/formResponse"
              method="post"
              onSubmit={submitForm}
            >
              <div className="p-2 w-full md:w-1/2">
                <div className="relative">
                  <label
                    htmlFor="name"
                    className="leading-7 text-sm text-white"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="entry.1267186267"
                    required
                    onChange={handleNameChange}
                    value={name}
                    className="w-full bg-gray-500 bg-opacity-50 rounded  focus:border-indigo-900  focus:ring-2  text-base outline-none text-white py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-full  md:w-1/2">
                <div className="relative">
                  <label
                    htmlFor="email"
                    className="leading-7 text-sm text-white"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="entry.473617694"
                    required
                    onChange={handleEmailChange}
                    value={email}
                    className="w-full bg-gray-500 bg-opacity-50 rounded  focus:border-indigo-900 focus:ring-2  text-base outline-none text-white py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  <label
                    htmlFor="message"
                    className="leading-7 text-sm text-white"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="entry.1964346972"
                    required
                    onChange={handleMessageChange}
                    value={message}
                    className="w-full bg-gray-500 bg-opacity-50 rounded  focus:border-indigo-900 focus:ring-2  h-32 text-base outline-none text-white py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  ></textarea>
                </div>
              </div>
              <div className="p-2 w-full">
                <button
                  type="submit"
                  className="flex justify-center mx-auto text-white trispace bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Send
                </button>
              </div>
            </form>
            {modal ? (
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={() => setModal(false)}
                  className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
                >
                  <motion.div
                    initial={{ scale: 0, rotate: "12.5deg" }}
                    animate={{ scale: 1, rotate: "0deg" }}
                    exit={{ scale: 0, rotate: "0deg" }}
                    onClick={(e) => e.stopPropagation()}
                    className="bg-gradient-to-br from-indigo-600 to-blue-600 text-white p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
                  >
                    <FiCheckCircle className="text-white/10 rotate-12 text-[250px] absolute z-0 -top-24 -left-24" />
                    <div className="relative z-10">
                      <div className="bg-white w-16 h-16 mb-2 rounded-full text-3xl text-blue-600 grid place-items-center mx-auto">
                        <FiCheckCircle />
                      </div>
                      <h3 className="text-3xl font-bold text-center mb-2">
                        Message Sent Succesfully
                      </h3>
                      <p className="text-center mb-6">
                        I will reply back via mail in 1-2 business days!
                      </p>
                      <div className="flex gap-2">
                        <button
                          onClick={() => setModal(false)}
                          className="bg-white hover:opacity-90 transition-opacity text-blue-600 font-semibold w-full py-2 rounded"
                        >
                          Understood!
                        </button>
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
              </AnimatePresence>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactComp;
