import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { GitHub, Mail, Reddit, WhatsApp } from "@mui/icons-material";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="bg-[#23385f]">
        <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8 ">
          <div className="md:flex md:justify-between">
            <div className="mb-6 md:mb-0">
              <a href="/" className="flex items-center">
                <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white trispace">
                  Abid Nafi
                </span>
              </a>
            </div>
            <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
              <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white trispace">
                  Links
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-4">
                    <Link
                      to="/projects"
                      className="hover:text-white hover:underline trispace"
                    >
                      Projects
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/tech"
                      className="hover:text-white hover:underline trispace"
                    >
                      Tech Stack
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white trispace">
                  Follow Me
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-4">
                    <a
                      href="https://github.com/MohammedAbidNafi"
                      className="hover:text-white hover:underline trispace"
                    >
                      Github
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://discordapp.com/users/347003846679068672"
                      className="hover:text-white hover:underline trispacey"
                    >
                      Discord
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-400 lg:my-8" />
          <div className="sm:flex sm:items-center sm:justify-between">
            <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
              © 2024{" "}
              <a href="https://margsglobal.com" className="hover:underline">
                MargsWeb
              </a>
              . All Rights Reserved.
            </span>
            <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
              Built using{" "}
              <a
                href="https://react.dev/"
                className="hover:text-white cursor-pointer"
              >
                ReactJS
              </a>
            </span>

            <div className="flex  mt-4 sm:justify-center sm:mt-0">
              <a
                href="mailto:abid@margsglobal.com"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              >
                <Mail />
                <span className="sr-only">Mail</span>
              </a>
              <a
                href="https://github.com/MohammedAbidNafi"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              >
                <GitHub />
                <span className="sr-only">GitHub account</span>
              </a>
              <a
                href="https://www.instagram.com/md_abid_nafi/"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              >
                <InstagramIcon />
                <span className="sr-only">Instagram</span>
              </a>
              <a
                href="https://www.linkedin.com/in/margs-global-0239b01b1/"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              >
                <LinkedInIcon />
                <span className="sr-only">Linkedin</span>
              </a>
              <a
                href="https://wa.me/919491951924?text="
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              >
                <WhatsApp />
                <span className="sr-only">Whatsapp</span>
              </a>
              <a
                href="https://www.reddit.com/user/AbidNafi"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              >
                <Reddit />
                <span className="sr-only">Reddit</span>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
