import React from "react";
import Languages from "./languages";
import Technologies from "./frontend";

function Stack() {
  return (
    <div className="flex flex-col w-full items-center justify-center  bg-gradient-to-t from-[#23385f] to-[#101827] pt-20">
      <p className="pt-5 text-3xl text-center text-white sm:text-4xl lg:text-5xl font-bold trispace">
        Tech Stack
      </p>

      <Technologies />

      <p className="pt-20 text-3xl text-center text-white sm:text-4xl lg:text-5xl font-bold trispace">
        Languages
      </p>
      <Languages />
    </div>
  );
}

export default Stack;
