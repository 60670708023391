import "./App.css";
import React from "react";
import Intro from "./components/Intro/intro";
import NavBar from "./components/NavBar/navbar";
import Profile from "./components/Profile/profile";
import Achievements from "./components/achievements/achievements";
import Testimonials from "./components/testimonials/testimonial";
import Footer from "./components/footer/footer";
import About from "./components/about/about";

function Home() {
  return (
    <html className="scroll-smooth">
      <div>
        <NavBar />
        <Intro />
        <About />
        <Profile />
        <Achievements />
        <Testimonials />
        <Footer />
      </div>
    </html>
  );
}

export default Home;
